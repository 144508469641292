import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactSVG } from 'react-svg';
import 'animate.css/animate.min.css';
import { useDispatch, useSelector } from 'react-redux';
import {
   setDeleteProduct,
   updateProductsInCart,
   selectProducts,
} from '../../redux/slices/productsSlice';
import 'react-lazy-load-image-component/src/effects/blur.css';
import link from '../../assets/svg/link-arrow.svg';
import trash from '../../assets/svg/trash.svg';

function CartItem({ el }) {
   const products = useSelector(selectProducts);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isModalClose, setIsModalClose] = useState(true);

   const dispatch = useDispatch();

   const handleAddProduct = () => {
      const productExists = products.find(
         (product) => product.productId === el.productId
      );
      if (productExists.quantity < 99) {
         dispatch(
            updateProductsInCart({
               productId: el.productId,
               quantity: productExists.quantity + 1,
            })
         );
      }
   };

   const handleReduceProduct = () => {
      const productExists = products.find(
         (product) => product.productId === el.productId
      );
      if (productExists.quantity > 1) {
         dispatch(
            updateProductsInCart({
               productId: el.productId,
               quantity: productExists.quantity - 1,
            })
         );
      }
   };
   const handleDeleteProduct = () => {
      setTimeout(() => {
         dispatch(setDeleteProduct(el.productId));
      }, 300);
      const li = document.getElementById(el.productId);
      li.classList.add('hide');
   };
   const handleModalOpen = () => {
      setIsModalOpen(true);
      setIsModalClose(false);
      window.history.pushState({ page: 'modal' }, 'Modal Page', '?img=modal');
   };
   const handleModalClose = (e) => {
      setIsModalClose(true);
      setTimeout(() => {
         e.stopPropagation();
         setIsModalOpen(false);
         window.history.replaceState(
            null,
            document.title,
            window.location.pathname
         );
      }, 300);
   };
   const handlePopstate = () => {
      setIsModalOpen(false);
   };
   useEffect(() => {
      const body = document.querySelector('body');
      const header = document.querySelector('header');
      if (body && window.innerWidth > 767) {
         body.style.overflow = isModalOpen ? 'hidden' : 'auto';
         // Verifică dacă nu este un dispozitiv mobil
         if (!/Mobi|Android/i.test(navigator.userAgent)) {
            body.style.paddingRight = isModalOpen ? '16px' : '0px';
            header.style.paddingRight = isModalOpen ? '31px' : '15px';
         }
      }
      window.addEventListener('popstate', handlePopstate);

      return () => {
         window.removeEventListener('popstate', handlePopstate);
         if (body) {
            body.style.overflow = 'auto';
            header.style.paddingRight = '15px';
            body.style.paddingRight = '0px';
         }
      };
   }, [isModalOpen]);

   return (
      <li className="cart-products-item" id={el.productId}>
         <div
            className="cart-products-item__img-wrapper"
            onClick={handleModalOpen}
         >
            <LazyLoadImage
               className="cart-products-item__img"
               src={el.imgs[0]}
               alt={el.name}
               effect="blur"
               placeholder={
                  <img
                     src={el.imgMin}
                     alt="Placeholder"
                     className="cart-products-item__placeholder"
                  />
               }
            />
            <div className="cart-products-item__open-icon">
               <ReactSVG className="cart-products-item__svg" src={link} />
            </div>
            <div className="cart-products-item__size">{el.size}</div>
            {el.color && (
               <div className="cart-products-item__color">{el.color}</div>
            )}
         </div>
         <div className="cart-products-item__info">
            <div className="cart-products-item__row">
               <h2 className="cart-products-item__name text">{el.name}</h2>
               <h2 className="cart-products-item__text text">{el.price}MDL</h2>
            </div>
            <div className="cart-products-item__row">
               <div className="cart-products-item__quantity">
                  <div
                     className="cart-products-item__qty-modificator"
                     onClick={handleAddProduct}
                  >
                     +
                  </div>
                  <h2 className="cart-products-item__text text">
                     {el.quantity} qty
                  </h2>
                  <div
                     className="cart-products-item__qty-modificator"
                     onClick={handleReduceProduct}
                  >
                     -
                  </div>
               </div>
               <div
                  className="cart-products-item__trash-icon"
                  onClick={handleDeleteProduct}
               >
                  <ReactSVG className="cart-products-item__svg" src={trash} />
               </div>
            </div>
         </div>

         {isModalOpen && (
            <div
               className={`modal ${isModalClose ? 'close' : 'open'}`}
               onClick={handleModalClose}
            >
               <span className="modal__close">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="40"
                     height="40"
                     viewBox="0 0 24 24"
                  >
                     <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        d="m7 7l10 10M7 17L17 7"
                     />
                  </svg>
               </span>
               <div className="modal__content">
                  <img
                     className="modal__img"
                     src={el.imgs[0]}
                     alt={el.name}
                     onClick={(e) => e.stopPropagation()}
                  />
               </div>
            </div>
         )}
      </li>
   );
}
export default CartItem;
