import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
   setAddProduct,
   updateProductsInCart,
   selectProducts,
} from '../../redux/slices/productsSlice';
import link from '../../assets/svg/link-arrow.svg';
import close from '../../assets/svg/close.svg';
import more from '../../assets/svg/more.svg';
import cart from '../../assets/svg/add-to-cart.svg';

function ProductItem({ el }) {
   const products = useSelector(selectProducts);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isModalClose, setIsModalClose] = useState(true);
   const [isMoreClicked, setIsMoreClicked] = useState(false);
   const [selectedSize, setSelectedSize] = useState('S');
   const [selectedColor, setSelectedColor] = useState('WHT');
   const [animations, setAnimations] = useState([]);
   const [selectedImageIndex, setSelectedImageIndex] = useState(0);

   const dispatch = useDispatch();

   const handleSizeChange = (e) => {
      const newSize = e.target.value;
      setSelectedSize(newSize);
   };
   const handleColorChange = (e) => {
      const newColor = e.target.value;
      setSelectedColor(newColor);
   };
   const handleAddProduct = () => {
      // Animație
      const timestamp = Date.now();
      setAnimations((prevAnimations) => [...prevAnimations, { id: timestamp }]);
      setTimeout(() => {
         setAnimations((prevAnimations) =>
            prevAnimations.filter((anim) => anim.id !== timestamp)
         );
      }, 1000);

      // Verificare dacă există deja un produs cu aceeași mărime
      const existingProductIndex = products.findIndex((product) => {
         if (el.colors) {
            return (
               product.productId === el.id + selectedSize + selectedColor &&
               product.size === selectedSize &&
               product.color === selectedColor
            );
         } else {
            return (
               product.productId === el.id + selectedSize &&
               product.size === selectedSize
            );
         }
      });

      if (existingProductIndex !== -1) {
         // Dacă produsul există, incrementăm cantitatea
         dispatch(
            updateProductsInCart({
               productId: products[existingProductIndex].productId,
               quantity: products[existingProductIndex].quantity + 1,
            })
         );
      } else {
         // Dacă produsul nu există, adăugăm un nou obiect
         dispatch(
            setAddProduct({
               ...el,
               productId: el.colors
                  ? el.id + selectedSize + selectedColor
                  : el.id + selectedSize, // Folosim un identificator unic
               quantity: 1,
               size: selectedSize,
               color: el.colors ? selectedColor : null,
            })
         );
      }
   };
   const handleMoreClick = () => {
      setIsMoreClicked(!isMoreClicked);
   };
   const handleModalOpen = (index) => {
      setSelectedImageIndex(index);
      setIsModalOpen(true);
      setIsModalClose(false);
      window.history.pushState({ page: 'modal' }, 'Modal Page', '?img=modal');
   };
   const handleModalClose = (e) => {
      setIsModalClose(true);
      setTimeout(() => {
         e.stopPropagation();
         setIsModalOpen(false);
         window.history.replaceState(
            null,
            document.title,
            window.location.pathname
         );
      }, 300);
   };
   const handlePopstate = () => {
      setIsModalOpen(false);
   };
   function isNewProduct(postedDate) {
      const currentDate = new Date();
      const postedDay = parseInt(postedDate.substring(0, 2), 10);
      const postedMonth = parseInt(postedDate.substring(3, 5), 10) - 1;
      const postedYear = parseInt(postedDate.substring(6, 8), 10) + 2000;
  
      const parsedPostedDate = new Date(postedYear, postedMonth, postedDay);
      const threeMonthsLater = new Date(parsedPostedDate);
      threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 1);
  
      return currentDate < threeMonthsLater;
  }
   const isNew = isNewProduct(el.date); // Verificăm dacă este produs nou

   useEffect(() => {
      const body = document.querySelector('body');
      const header = document.querySelector('header');
      if (body && window.innerWidth > 767) {
         body.style.overflow = isModalOpen ? 'hidden' : 'auto';
         // Verifică dacă nu este un dispozitiv mobil
         if (!/Mobi|Android/i.test(navigator.userAgent)) {
            body.style.paddingRight = isModalOpen ? '16px' : '0px';
            header.style.paddingRight = isModalOpen ? '31px' : '15px';
         }
      }
      window.addEventListener('popstate', handlePopstate);

      return () => {
         window.removeEventListener('popstate', handlePopstate);
         if (body) {
            body.style.overflow = 'auto';
            header.style.paddingRight = '15px';
            body.style.paddingRight = '0px';
         }
      };
   }, [isModalOpen]);

   return (
      <div className={`product-item ${isMoreClicked ? 'more' : ''}`}>
         {el.imgs.length > 1 ? (
            <Swiper
               scrollbar={{
                  hide: false,
               }}
               modules={[Scrollbar]}
               className="mySwiper"
            >
               {el.imgs.map((img, index) => (
                  <SwiperSlide key={index}>
                     <LazyLoadImage
                        src={img}
                        alt={ `styzzo shop ${el.type} ${el.name}` }
                        effect="blur"
                        className="swiper-img"
                        onClick={() => handleModalOpen(index)} // Modificare: deschiderea modalului pe clic pe imagine
                     />
                  </SwiperSlide>
               ))}
            </Swiper>
         ) : (
            <LazyLoadImage
               className="product-item__img"
               src={el.imgs[0]}
               alt={el.name}
               effect="blur"
               placeholder={
                  <img
                     src={el.imgsMin[0]}
                     alt="Placeholder"
                     className="product-item__placeholder"
                  />
               }
               onClick={() => handleModalOpen(0)}
            />
         )}
         <div className="product-item__open-icon">
            <ReactSVG className="product-item__svg" src={link} />
         </div>
         <div className="product-item__more-icon" onClick={handleMoreClick}>
            <ReactSVG
               className="product-item__svg product-item__svg-dots"
               src={more}
            />
            <ReactSVG
               className="product-item__svg product-item__svg-cross"
               src={close}
            />
         </div>
         <div className="product-item__select-size">
            <select
               value={selectedSize}
               onChange={(e) => {
                  handleSizeChange(e);
               }}
            >
               {el.sizes.map((size) => (
                  <option
                     className="product-item__option"
                     key={size}
                     value={size}
                  >
                     {size}
                  </option>
               ))}
            </select>
         </div>
         {el.colors && (
            <div className="product-item__select-color">
               <select
                  value={selectedColor}
                  onChange={(e) => {
                     handleColorChange(e);
                  }}
               >
                  {el.colors.map((color) => (
                     <option
                        className="product-item__option"
                        key={color}
                        value={color}
                     >
                        {color}
                     </option>
                  ))}
               </select>
            </div>
         )}
         <button
            className="product-item__add-to-card "
            onClick={handleAddProduct}
         >
            <ReactSVG
               className="product-item__svg product-item__svg-add"
               src={cart}
            />
            {animations.map(({ id }) => (
               <div key={id} className="animation">
                  +1
               </div>
            ))}
         </button>
         <div className="product-item__info">
            <h2 className="product-item__text text">Material: {el.material}</h2>
            <p className="product-item__text text">
               Size: {el.sizes[0]} - {el.sizes[el.sizes.length - 1]}
            </p>
            <p className="product-item__text text">Type: {el.type}</p>
            <p className="product-item__text text">Name: {el.name}</p>
         </div>
         <div className="product-item__details">
            <h2 className="product-item__name text">{el.name}</h2>
            <h2 className="product-item__text text">{el.price}MDL</h2>
            {el.oldPrice && (
               <strike className="product-item__text text">
                  <sup style={{ lineHeight: 0 }}>{el.oldPrice}MDL</sup>
               </strike>
            )}
            {isNew && <span className="product-item__isNew">NEW</span>}
         </div>
         {isModalOpen && (
            <div
               className={`modal ${isModalClose ? 'close' : 'open'}`}
               onClick={handleModalClose}
            >
               <span className="modal__close">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="40"
                     height="40"
                     viewBox="0 0 24 24"
                  >
                     <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        d="m7 7l10 10M7 17L17 7"
                     />
                  </svg>
               </span>

               <div className="modal__content">
                  <img
                     className="modal__img"
                     src={el.imgs[selectedImageIndex]} // Modificare: afișarea imaginii corespunzătoare indexului
                     alt={el.name}
                     onClick={(e) => e.stopPropagation()}
                  />
               </div>
            </div>
         )}
      </div>
   );
}

export default ProductItem;
