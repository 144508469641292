import { createSlice } from '@reduxjs/toolkit';

const initialState = [
   
];

const productsSlice = createSlice({
   name: 'products',
   initialState,
   reducers: {
      setAddProduct: (state, action) => {
         state.push({ ...action.payload, quantity: 1 });
      },
      setDeleteProduct: (state, action) => {
         return state.filter((product) => product.productId !== action.payload);
      },
      setDeleteProducts: () => {
         return [];
      },
      updateProductsInCart: (state, action) => {
         const { productId, quantity } = action.payload;
         const productToUpdate = state.find(
            (product) => product.productId === productId
         );
         if (productToUpdate) {
            productToUpdate.quantity = quantity;
         }
      },
   },
});

export const selectProducts = (state) => state.products;

export const {
   setAddProduct,
   setDeleteProduct,
   setDeleteProducts,
   updateProductsInCart,
} = productsSlice.actions;

export default productsSlice.reducer;
