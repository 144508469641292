import React, { useEffect } from 'react';
import CartProducts from '../components/Cart/CartProducts';
import YourProducts from '../components/Cart/YourProducts';
import CartForm from '../components/Cart/CartForm';

function Cart() {
   useEffect(() => {
      document.title = 'STYZZO | Cart';
   }, []);
   return (
      <>
         <YourProducts />
         <CartProducts />
         <CartForm />
      </>
   );
}

export default Cart;
