import { v4 as uuidv4 } from 'uuid';

import asap from '../assets/img/posters/asap.webp';
import boyfriend from '../assets/img/posters/boyfriend.webp';
import childish1 from '../assets/img/posters/childish-gambino.webp';
import childish2 from '../assets/img/posters/childish-gambino2.webp';
import creator1 from '../assets/img/posters/creator.webp';
import creator2 from '../assets/img/posters/creator2.webp';
import creator3 from '../assets/img/posters/creator3.webp';
import creatorV2 from '../assets/img/posters/creator-v2.webp';
import creatorV2T from '../assets/img/posters/creator-v2-t.webp';
import donBerman from '../assets/img/posters/don-berman.webp';
import e301 from '../assets/img/posters/e30.webp';
import e302 from '../assets/img/posters/e302.webp';
import grezelda from '../assets/img/posters/grezelda.webp';
import styzzoT from '../assets/img/posters/styzzo-t.webp';
import f1 from '../assets/img/posters/f1.webp';
import f1Back from '../assets/img/posters/f1-back.webp';
import stz3 from '../assets/img/posters/STZ 3.webp';

//min
import asapMin from '../assets/img/posters/min/asap.webp';
import boyfriendMin from '../assets/img/posters/min/boyfriend.webp';
import childish1Min from '../assets/img/posters/min/childish-gambino.webp';
import childish2Min from '../assets/img/posters/min/childish-gambino2.webp';
import creator1Min from '../assets/img/posters/min/creator.webp';
import creator2Min from '../assets/img/posters/min/creator2.webp';
import creator3Min from '../assets/img/posters/min/creator3.webp';
import creatorV2Min from '../assets/img/posters/min/creator-v2.webp';
import creatorV2TMin from '../assets/img/posters/min/creator-v2-t.webp';
import donBermanMin from '../assets/img/posters/min/don-berman.webp';
import e301Min from '../assets/img/posters/min/e30.webp';
import e302Min from '../assets/img/posters/min/e302.webp';
import grezeldaMin from '../assets/img/posters/min/grezelda.webp';
import styzzoTMin from '../assets/img/posters/min/styzzo-t.webp';
import f1Min from '../assets/img/posters/min/f1.webp';
import f1BackMin from '../assets/img/posters/min/f1-back.webp';
import stz3Min from '../assets/img/posters/min/STZ 3.webp';

const preProducts = [
   {
      imgs: [stz3],
      imgsMin: [stz3Min],
      name: 'STZ3',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      oldPrice: 450,
      date: '15.08.24',
   },
   {
      imgs: [f1, f1Back],
      imgsMin: [f1Min, f1BackMin],
      name: 'F1',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      oldPrice: 450,
      date: '08.06.24',
   },
   {
      imgs: [styzzoT],
      imgsMin: [styzzoTMin],
      name: 'Styzzo',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      oldPrice: 450,
      date: '15.04.24',
   },
   {
      imgs: [boyfriend],
      imgsMin: [boyfriendMin],
      name: 'Boyfriend',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      date: '16.02.24',
   },
   {
      imgs: [creator1, creator2, creator3],
      imgsMin: [creator1Min, creator2Min, creator3Min],
      colors: ['WHT', 'BLK'],
      name: 'Tyler The Creator',
      type: 'hoodie',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 649,
      date: '13.01.24',
   },
   {
      imgs: [e301, e302],
      imgsMin: [e301Min, e302Min],
      colors: ['WHT', 'BLK'],
      name: 'E30',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      date: '18.12.23',
   },
   {
      imgs: [childish1],
      imgsMin: [childish1Min],
      name: 'Childish Gambino',
      type: 'hoodie',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 649,
      date: '17.12.23',
   },
   {
      imgs: [childish2],
      imgsMin: [childish2Min],
      name: 'Childish Gambino',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      date: '17.12.23',
   },
   {
      imgs: [grezelda],
      imgsMin: [grezeldaMin],
      name: 'Grezelda',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      oldPrice: 400,
      date: '03.12.23',
   },
   {
      imgs: [asap],
      imgsMin: [asapMin],
      name: 'Asap Rocky',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      date: '17.11.23',
   },
   {
      imgs: [creatorV2],
      imgsMin: [creatorV2Min],
      name: 'Tyler The Creator 2',
      type: 'hoodie',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 649,
      date: '16.11.23',
   },
   {
      imgs: [creatorV2T],
      imgsMin: [creatorV2TMin],
      name: 'Tyler The Creator 2',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      date: '16.11.23',
   },
   {
      imgs: [donBerman],
      imgsMin: [donBermanMin],
      name: 'Don Berman',
      type: 't-shirt',
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      price: 349,
      date: '14.11.23',
   },
];

const productsData = preProducts.map((product) => ({
   ...product,
   material: 'cotton 100%',
   size: 'S',
   quantity: 0,
   id: uuidv4(), // Add unique ID for each product
}));

export default productsData;
