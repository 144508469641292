import React from 'react';
import Section from '../Utils/Section';
import Title from '../Utils/Title';
import { ReactSVG } from 'react-svg';
import link from '../../assets/svg/link-arrow.svg';
import hoodies from '../../assets/img/posters/creator-v2.webp';
import styzzo from '../../assets/img/posters/styzzo-t.webp';
import { Link } from 'react-router-dom';

export default function Types() {
   return (
      <Section class="types">
         <Link to="/hoodies" className="types__item">
            <Title class="types__title">Hoodies</Title>
            <div className="types__link">
               <ReactSVG className="types__svg" src={link} />
            </div>
            <img className="types__img" src={hoodies} alt="hoodies" />
         </Link>
         <Link to="/tshirts" className="types__item">
            <Title class="types__title">T-SHIRTS</Title>
            <div className="types__link">
               <ReactSVG className="types__svg" src={link} />
            </div>
            <img className="types__img" src={styzzo} alt="styzzo" />
         </Link>
         <Link to="/pants" className="types__item">
            <Title class="types__title">PANTS</Title>
            <div className="types__link">
               <ReactSVG className="types__svg" src={link} />
            </div>
            {/*<img className="types__img" src={clothes} alt="websites" />*/}
            <p className="types__colab">COMMING SOON</p>
         </Link>
      </Section>
   );
}
