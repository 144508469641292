import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { selectProducts } from '../../redux/slices/productsSlice';
import DarkModeToggle from './DarkModeToggle';
import MenuItem from './MenuItem';
import cixdyIco from '../../assets/svg/logo-svg.svg';

function Header() {
   const products = useSelector(selectProducts);

   const totalQuantity = products.reduce(
      (total, product) => total + product.quantity,
      0
   );

   return (
      <header className={`header`}>
         <div className="container">
            <div className="header__body">
               <div className="header__logo">
                  <ReactSVG className="header__svg" src={cixdyIco} />
               </div>
               <div className="header__left">
                  <nav className="menu">
                     <ul className="menu__list">
                        <MenuItem path="/home">Home</MenuItem>
                        <MenuItem path="/">Products</MenuItem>
                        <Link to="/cart" className="menu__cart">
                           <p>Cart</p>
                           <span>
                              {totalQuantity < 100 ? totalQuantity : 99}
                           </span>
                        </Link>
                     </ul>
                  </nav>
                  <DarkModeToggle />
               </div>
            </div>
         </div>
      </header>
   );
}

export default Header;
