import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import Section from '../Utils/Section';
import Title from '../Utils/Title';
import Text from '../Utils/Text';
import clothes from '../../assets/img/main/cipi.jpg';
import link from '../../assets/svg/link-arrow.svg';

export default function Promocode() {
   return (
      <Section class="promocode">
         <div className="promocode__info">
            <Title>
               New promocode<b> 10% </b>discount! <i>ConnectToUniverse</i>
            </Title>
            <Text class="promocode__text">
               At STYZZO, we have a great promotion for you! If your order
               exceeds 1000 MDL, you'll get a 10% discount! What are you waiting
               for?{' '}
               <span style={{ textDecoration: 'underline', opacity: '0.7' }}>
                  Click here to start shopping and be part of the free and
                  modern fashion!
               </span>
            </Text>
         </div>
         <img
            className="promocode__img"
            src={clothes}
            alt="collection new styzzo collection"
         />
         <Link className="promocode__more-wrapper" to="/">
            <p>Go shopping</p>
            <div className="promocode__link">
               <ReactSVG className="promocode__svg" src={link} />
            </div>
         </Link>
      </Section>
   );
}
