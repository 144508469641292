function Footer() {
   return (
      <footer className="footer">
         <div className="container">
            <div className="footer__body">
               <p>© STYZZO • A High-Quality Studio • 2024</p>
            </div>
         </div>
      </footer>
   );
}

export default Footer;
