import React from 'react';
import { Link } from 'react-router-dom';

function MenuItem(props) {
   return (
      <li className="menu__item">
         <Link to={props.path}>{props.children}</Link>
      </li>
   );
}

export default MenuItem;
