import React, { useEffect } from 'react';
import ProductsGrid from '../components/Products/ProductsGrid';
import WhatProducts from '../components/Products/WhatProducts';
function Home() {
   useEffect(() => {
      document.title = 'STYZZO | Store';
   }, []);
   return (
      <>
         <WhatProducts />
         <ProductsGrid />
      </>
   );
}
export default Home;
