import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import moon from '../../assets/svg/moon.svg';
import sun from '../../assets/svg/sun.svg';

function DarkModeToggle() {
   const [darkMode, setDarkMode] = useState(
      localStorage.getItem('darkMode') === 'enabled'
   );

   useEffect(() => {
      const metaThemeColor = document.querySelector('#theme-color-meta');
      if (darkMode) {
         document.body.classList.add('darkmode');
         localStorage.setItem('darkMode', 'enabled');
         metaThemeColor.setAttribute('content', '#f2f2f2');
      } else {
         document.body.classList.remove('darkmode');
         localStorage.setItem('darkMode', 'disabled');
         metaThemeColor.setAttribute('content', '#0e0e0c');
      }
   }, [darkMode]);

   const toggleDarkMode = () => {
      setDarkMode(!darkMode);
   };

   return (
      <ul className="header__settings settings">
         <li className="settings__item">
            <button
               className="settings__mode-btn"
               id="light-mode-toggle"
               onClick={toggleDarkMode}
            >
               <ReactSVG className="settings__icon settings__dark" src={sun} />
               <ReactSVG
                  className="settings__icon settings__light"
                  src={moon}
               />
            </button>
         </li>
      </ul>
   );
}

export default DarkModeToggle;
