import { ReactSVG } from 'react-svg';
import link from '../../assets/svg/link-arrow.svg';

export default function Partners() {
   return (
      <section className="partners">
         <div className="container">
            <a href="https://daniel-diacon.online" className="partners__body">
               <p className="partners__title">Our Partner</p>
               <div className="partners__partners scrolling-text-container">
                  <p className="partners__partner" id="scrolling-text">
                     daniel <i>the wise choice </i> | daniel{' '}
                     <i>the wise choice </i> | daniel <i>the wise choice </i> |
                     daniel <i>the wise choice </i> | daniel{' '}
                     <i>the wise choice </i> | daniel <i>the wise choice </i> |
                     daniel <i>the wise choice </i> | daniel{' '}
                     <i>the wise choice </i> |
                  </p>
               </div>
               <div className="partners__link">
                  <ReactSVG className="partners__svg" src={link} />
               </div>
            </a>
         </div>
      </section>
   );
}
