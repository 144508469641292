import React from 'react';
import productsData from '../../data/productsData';
import Section from '../Utils/Section';
import ProductItem from './ProductItem';

export default function ProductsGrid() {
   return (
      <Section class="products">
         <div className="products__grid">
            {productsData.map((el, id) => (
               <ProductItem el={el} key={id} />
            ))}
         </div>
      </Section>
   );
}
