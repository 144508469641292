import { ReactSVG } from 'react-svg';
import link from '../../assets/svg/link-arrow.svg';

export default function WhatProducts() {
   return (
      <section className="partners">
         <div className="container">
            <div className="partners__body">
               <p className="partners__title">Our Products</p>
               <div className="partners__partners scrolling-text-container">
                  <p className="partners__partner" id="scrolling-text">
                     Hoodie | T-shirts | Pants | Hoodie | T-shirts | Pants |
                     Hoodie | T-shirts | Pants | Hoodie | T-shirts | Pants |
                     Hoodie | T-shirts | Pants | Hoodie | T-shirts | Pants |
                     Hoodie | T-shirts | Pants | Hoodie | T-shirts | Pants |
                     Hoodie | T-shirts | Pants | Hoodie | T-shirts | Pants |
                     Hoodie | T-shirts | Pants | Hoodie | T-shirts | Pants |
                     Hoodie | T-shirts | Pants | Hoodie | T-shirts | Pants |
                     Hoodie | T-shirts | Pants | Hoodie | T-shirts | Pants |
                  </p>
               </div>
               <div className="partners__link">
                  <ReactSVG className="what-products__svg" src={link} />
               </div>
            </div>
         </div>
      </section>
   );
}
