import React, { useEffect } from 'react';

import TShirtsItems from '../components/TShirts/TShirtsItems';
function Home() {
   useEffect(() => {
      document.title = 'STYZZO | T-Shirts';
   }, []);
   return (
      <>
         <TShirtsItems />
      </>
   );
}
export default Home;
