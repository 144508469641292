import React from 'react';
import { ReactSVG } from 'react-svg';
import Section from '../Utils/Section';
import Title from '../Utils/Title';
import Text from '../Utils/Text';
import clothes from '../../assets/img/main/clothes.jpg';
import link from '../../assets/svg/link-arrow.svg';
import { scroller } from 'react-scroll';

export default function Intro() {
   const goToPageAndScroll = async (selector) => {
      scroller.scrollTo(selector, {
         duration: 500,
         smooth: true,
         spy: true,
      });
   };
   return (
      <Section class="intro">
         <div className="intro__info">
            <Title>WELcome to styzzo</Title>
            <Text class="intro__text">
               Styzzo offers high-quality custom clothing, including T-shirts
               and hoodies, with unique design and attention to detail. Each
               piece is an expression of individuality and style, ensuring
               comfort and originality.
            </Text>
         </div>
         <img
            className="intro__img"
            src={clothes}
            alt="styzzo shop style edit poster instagram"
         />
         <button
            className="intro__more-wrapper"
            onClick={() => goToPageAndScroll('types')}
         >
            <p>See more</p>
            <div className="intro__link">
               <ReactSVG className="intro__svg" src={link} />
            </div>
         </button>
      </Section>
   );
}
