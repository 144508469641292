import React, { useEffect } from 'react';
import Partners from '../components/HomePage/Partners';
import Intro from '../components/HomePage/Intro';
import Types from '../components/HomePage/Types';
import Promocode from '../components/HomePage/Promocode';
import FollowTitle from '../components/HomePage/FollowTitle';
import Follow from '../components/HomePage/Follow';
import Purpose from '../components/HomePage/Purpose';
import LastProductsTitle from '../components/HomePage/LastProductsTitle';
import LastProducts from '../components/HomePage/LastProducts';
function Home() {
   useEffect(() => {
      document.title = 'STYZZO | Home';
   }, []);
   return (
      <>
         <Partners />
         <Intro />
         <Types name="types" />
         <Promocode />
         <LastProductsTitle />
         <LastProducts />
         <FollowTitle />
         <Follow />
         <Purpose />
      </>
   );
}
export default Home;
