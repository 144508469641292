import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import Section from '../Utils/Section';
import Title from '../Utils/Title';
import Text from '../Utils/Text';
import link from '../../assets/svg/link-arrow.svg';

export default function Purpose() {
   return (
      <Section class="purpose">
         <div className="purpose__info">
            <Title>Out Purpose!</Title>
            <Text class="purpose__text">
               At STYZZO, we inspire courage and authenticity. Our goal is to
               provide you with a shopping experience that allows you to express
               yourself freely through fashion. Whether your style is bold or
               subtle, STYZZO is the place to find pieces that reflect your
               personality.{' '}
               <span style={{ textDecoration: 'underline', opacity: '0.7' }}>
                  Click here to start shopping and be part of the free and
                  modern fashion!
               </span>
            </Text>
         </div>
         <Link className="purpose__more-wrapper" to="/">
            <p>Go shopping</p>
            <div className="purpose__link">
               <ReactSVG className="purpose__svg" src={link} />
            </div>
         </Link>
      </Section>
   );
}
