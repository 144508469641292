import React, { useEffect } from 'react';
function NotFound() {
   const smiles = ['(꩜ᯅ꩜)⁭', '(¬⤙¬ )', 'ʕ•ᴥ•ʔ', '(• ᴖ •)', '(._.`)'];
   const randNum = Math.floor(Math.random() * 5);
   const smile = smiles[randNum];
   useEffect(() => {
      document.title = 'STYZZO | 404';
   }, []);
   return (
      <section className="error">
         <p className="error__title">{smile}</p>
         <p className="error__text">Unfortunately, this page does not exist.</p>
      </section>
   );
}
export default NotFound;
