import React from 'react';
import Section from '../Utils/Section';
import { ReactSVG } from 'react-svg';
import insta from '../../assets/svg/insta.svg';
import tiktok from '../../assets/svg/tiktok.svg';
import facebook from '../../assets/svg/facebook.svg';

export default function Follow() {
   return (
      <Section class="follow">
         <a
            href="https://www.instagram.com/styzzostore/"
            className="follow__item"
         >
            <div className="follow__link">
               <ReactSVG className="follow__svg" src={insta} />
            </div>
            <p className="follow__name">@styzzostore</p>
         </a>
         <a href="https://www.tiktok.com/@styzzo_md" className="follow__item">
            <div className="follow__link">
               <ReactSVG className="follow__svg" src={tiktok} />
            </div>
            <p className="follow__name">@styzzo_md</p>
         </a>
         <a
            href="https://www.facebook.com/profile.php?id=61559263492162"
            className="follow__item"
         >
            <div className="follow__link">
               <ReactSVG className="follow__svg" src={facebook} />
            </div>
            <p className="follow__name">@styzzostore</p>
         </a>
      </Section>
   );
}
