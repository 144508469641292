import React, { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import emailjs from '@emailjs/browser';
import Section from '../Utils/Section';
import Title from '../Utils/Title';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectProducts,
   setDeleteProducts,
} from '../../redux/slices/productsSlice';
import loading from '../../assets/svg/loading.svg';
import confirm from '../../assets/svg/confirm.svg';
import closeAnimate from '../../assets/svg/close-animate.svg';

function CartForm() {
   const products = useSelector(selectProducts);
   const [promocode, setPromocode] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [isSuccess, setIsSuccess] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');
   const [errors, setErrors] = useState({
      fullname: '',
      email: '',
      number: '',
   });

   const dispatch = useDispatch();
   const form = useRef();

   const calculateTotalPricePromocode = (products, promocode) => {
      const PROMOCODE = [
         { name: 'ConnectToUniverse', discount: 0.1 },
         { name: 'CIXDYDAYOPEN', discount: 0.05 },
      ];

      let total = products.reduce((total, product) => {
         return total + product.price * product.quantity;
      }, 50);

      let appliedDiscount = false;
      let discount = 0;
      let codeDiscount = 0;
      PROMOCODE.forEach((code) => {
         if (promocode === code.name && total > 999) {
            codeDiscount = code.discount;
            discount = Math.floor(total * codeDiscount); // Apply discount
            total = Math.floor(total - discount);
            appliedDiscount = true;
         }
      });

      return { total, appliedDiscount, discount, codeDiscount };
   };

   const totalPriceInfo = calculateTotalPricePromocode(products, promocode);

   const sendEmail = (e) => {
      e.preventDefault();
      setIsLoading(true);

      if (validateForm()) {
         setTimeout(() => {
            emailjs
               .sendForm('service_yxusswj', 'template_p9gosmm', form.current, {
                  publicKey: 'DpoELOLeHwJgSFZoh',
               })
               .then(
                  () => {
                     console.log('SUCCESS!');
                     setIsSuccess(true);
                     setIsLoading(false);
                     setErrorMessage('');
                     e.target.reset();
                     dispatch(setDeleteProducts());
                  },
                  (error) => {
                     console.log('FAILED...', error.text);
                     setIsSuccess(false);
                     setIsLoading(false);
                     setErrorMessage('Something went wrong.');
                  }
               );
         }, 1000);
      } else {
         setIsLoading(false);
      }
   };

   const productList = products.reduce((acc, product) => {
      const totalPrice = product.quantity * product.price;
      const productText = `${product.name} — ${product.type} — ${
         product.size
      } ${product.color ? `— ${product.color} ` : ''}: ${product.quantity} x ${
         product.price
      } = ${totalPrice} MDL`;

      return `${acc}${productText}     |   \n   `;
   }, `Total Price : ${totalPriceInfo.total} mdl | Promocode applied: ${totalPriceInfo.appliedDiscount} |`);

   const handleClose = () => {
      setIsLoading(false);
      setIsSuccess(false);
      setErrorMessage('');
   };

   const validateForm = () => {
      const fullname = form.current.elements['form_name'].value;
      const email = form.current.elements['from_email'].value;
      const number = form.current.elements['form_number'].value;

      let isValid = true;

      // Validare fullname
      if (fullname === '') {
         setErrors((prevErrors) => ({
            ...prevErrors,
            fullname: 'Please enter your full name.',
         }));
         isValid = false;
      } else {
         setErrors((prevErrors) => ({ ...prevErrors, fullname: '' }));
      }

      // Validare email
      if (!email.endsWith('@gmail.com')) {
         setErrors((prevErrors) => ({
            ...prevErrors,
            email: 'Please enter a valid Gmail address.',
         }));
         isValid = false;
      } else {
         setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
      }

      // Validare număr de telefon
      if (number.length !== 9 || isNaN(number)) {
         setErrors((prevErrors) => ({
            ...prevErrors,
            number: 'Please enter a phone number with exactly 9 digits.',
         }));
         isValid = false;
      } else {
         setErrors((prevErrors) => ({ ...prevErrors, number: '' }));
      }

      return isValid; // Returnează true dacă formularul este valid
   };

   return (
      <Section class="cart-payment">
         {isLoading && (
            <div className="order-popup loading">
               <ReactSVG className="order-popup__icon" src={loading} />
               <p className="order-popup__text text">Sending...</p>
               <p className="order-popup__sub-text text">
                  Please wate a minute.
               </p>
            </div>
         )}
         {isSuccess && (
            <div className="order-popup success" onClick={handleClose}>
               <ReactSVG className="order-popup__icon" src={confirm} />
               <p className="order-popup__text text">Sended!</p>
               <p className="order-popup__sub-text text">
                  Thank you for your order! <br />
                  You will receive a call shortly.
               </p>
            </div>
         )}
         {errorMessage && (
            <div className="order-popup error" onClick={handleClose}>
               <ReactSVG className="order-popup__icon" src={closeAnimate} />
               <p className="order-popup__text text">{errorMessage}</p>
               <p className="order-popup__sub-text text">
                  Please try again later.
               </p>
            </div>
         )}
         {!!products.length && (
            <div className="cart-form-totals">
               <Title class="cart-form-totals__title">Order Totals</Title>
               <ul className="cart-form-totals__grid">
                  {products.map((product, i) => (
                     <li
                        className="cart-form-totals__item"
                        key={product.productId + 'total'}
                     >
                        <div className="cart-form-totals__name">
                           {`${product.name} — ${product.size}  ${
                              product.color ? `— ${product.color}` : ''
                           }`}
                        </div>
                        <div className="cart-form-totals__price">
                           X{product.quantity}{' '}
                           {product.price * product.quantity}MDL
                        </div>
                     </li>
                  ))}
                  <li className="cart-form-totals__totals">
                     <div className="cart-form-totals__name">Totals</div>
                     <div className="cart-form-totals__price">
                        {totalPriceInfo.total - 50 + totalPriceInfo.discount}{' '}
                        MDL
                     </div>
                  </li>
               </ul>
            </div>
         )}
         {!!products.length && (
            <div className="cart-form">
               <Title class="cart-form__title">Personal Data</Title>
               <form
                  className="cart-form__form"
                  ref={form}
                  onSubmit={sendEmail}
               >
                  <div className="cart-form__content">
                     <div className="cart-form__inputs">
                        <div className="cart-form__row">
                           <label
                              htmlFor="fullname"
                              className="cart-form__label"
                           >
                              Full Name
                           </label>
                           <input
                              name="form_name"
                              id="fullname"
                              type="text"
                              className="cart-form__input"
                              placeholder="Enter your fullname"
                           />
                        </div>
                        {!!errors.fullname && (
                           <span className="error-message">
                              {errors.fullname}
                           </span>
                        )}
                        <div className="cart-form__row">
                           <label htmlFor="email" className="cart-form__label">
                              E-mail
                           </label>
                           <input
                              name="from_email"
                              id="email"
                              type="email"
                              className="cart-form__input"
                              placeholder="yourEmail@gmail.com"
                           />
                        </div>
                        {!!errors.email && (
                           <span className="error-message">{errors.email}</span>
                        )}
                        <div className="cart-form__row">
                           <label htmlFor="number" className="cart-form__label">
                              Telefon Number
                           </label>
                           <input
                              name="form_number"
                              id="number"
                              type="tel"
                              className="cart-form__input"
                              placeholder="0XX XXX XXX"
                           />
                        </div>
                        {!!errors.number && (
                           <span className="error-message">
                              {errors.number}
                           </span>
                        )}
                        <div className="cart-form__row">
                           <label
                              htmlFor="promocode"
                              className="cart-form__label"
                           >
                              Promocode
                           </label>
                           <input
                              name="form_promocode"
                              id="promocode"
                              type="text"
                              className="cart-form__input"
                              defaultValue={promocode}
                              placeholder="Promocode (optional)"
                              onChange={(e) => setPromocode(e.target.value)}
                           />

                           <input
                              type="textarea"
                              value={productList}
                              onChange={() => productList}
                              name="message"
                              style={{
                                 opacity: 0,
                                 visibility: 'hidden',
                                 height: 0,
                                 width: 0,
                              }}
                           />
                        </div>
                     </div>

                     <div className="cart-form__final-totals">
                        <div className="cart-form__row-totals">
                           <div className="cart-form__label">
                              Delivery — Chisinau
                           </div>
                           <div>50MDL</div>
                        </div>
                        {totalPriceInfo.appliedDiscount && (
                           <div className="cart-form__row-totals">
                              <div className="cart-form__label">
                                 Discount applied!
                              </div>
                              <div>
                                 -{totalPriceInfo.discount} MDL
                                 <sup className="green">
                                    {totalPriceInfo.codeDiscount * 100}%
                                 </sup>
                              </div>
                           </div>
                        )}
                        <div className="cart-form__row-totals">
                           <div className="cart-form__label">Total price</div>
                           <div>
                              {totalPriceInfo.total} MDL{' '}
                              {totalPriceInfo.appliedDiscount && (
                                 <sup className="red">
                                    {totalPriceInfo.total +
                                       totalPriceInfo.discount}{' '}
                                    MDL
                                 </sup>
                              )}
                           </div>
                        </div>
                     </div>
                  </div>

                  <button type="submit" className="button">
                     Take an order
                  </button>
               </form>
            </div>
         )}
      </Section>
   );
}

export default CartForm;
