import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

function Section(props) {
   return (
      <ScrollAnimation
         animateIn="animate__fadeIn"
         animateInDuration={100}
         animateOutDuration={100}
         animateInDelay={0}
         animateOutDelay={0}
      >
         <section className={`${props.class} section`}>
            <div className="container">
               <div className={`${props.class}__body section__body`}>
                  {props.children}
               </div>
            </div>
         </section>
      </ScrollAnimation>
   );
}
export default Section;
