import React from 'react';
import productsData from '../../data/productsData';
import Section from '../Utils/Section';
import ProductItem from '../Products/ProductItem';

export default function TShirtsItems() {
   // Filtrăm produsele care au doar tipul "t-shirts"
   const tShirts = productsData.filter((product) => product.type === 't-shirt');

   return (
      <Section class="products">
         <div className="products__grid">
            {tShirts.map((el, id) => (
               <ProductItem el={el} key={id} />
            ))}
         </div>
      </Section>
   );
}
