export default function FollowTitle() {
   return (
      <section className="last-products">
         <div className="container">
            <div className="last-products__body">
               <div className="last-products__partners scrolling-text-container">
                  <p className="last-products__partner" id="scrolling-text">
                     Follow us on | Tik tok | Instagram | facebook | Follow us
                     on | Tik tok | Instagram | facebook | Follow us on | Tik
                     tok | Instagram | facebook | Follow us on | Tik tok |
                     Instagram | facebook | Follow us on | Tik tok | Instagram |
                     facebook | Follow us on | Tik tok | Instagram | facebook |
                     Follow us on | Tik tok | Instagram | facebook | Follow us
                     on | Tik tok | Instagram | facebook | Follow us on | Tik
                     tok | Instagram | facebook | Follow us on | Tik tok |
                     Instagram | facebook |
                  </p>
               </div>
            </div>
         </div>
      </section>
   );
}
