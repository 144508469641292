import React, { useEffect } from 'react';
import HoodiesItems from '../components/Hoodies/HoodiesItems';
function Home() {
   useEffect(() => {
      document.title = 'STYZZO | Hoodies';
   }, []);
   return (
      <>
         <HoodiesItems />
      </>
   );
}
export default Home;
