import React from 'react';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../redux/slices/productsSlice';
import CartItem from './CartItem';
import Section from '../Utils/Section';
import { Link } from 'react-router-dom';

function CartProducts() {
   const products = useSelector(selectProducts);
   return (
      <Section class="cart-products">
         {products.length === 0 ? (
            <Link to="/" className="cart-products__more-1 cart-products__more">
               +
            </Link>
         ) : (
            <ul className="cart-products__grid">
               {products.map((product, i) => (
                  <CartItem
                     key={product.productId}
                     id={product.id}
                     el={product}
                  ></CartItem>
               ))}
               <Link
                  to="/"
                  className="cart-products__more-2 cart-products__more"
               >
                  +
               </Link>
            </ul>
         )}
      </Section>
   );
}

export default CartProducts;
