import React, { useEffect } from 'react';
import {
   BrowserRouter as Router,
   Route,
   Routes,
   useLocation,
} from 'react-router-dom';
import './App.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Products from './pages/Products';
import Hoodies from './pages/Hoodies';
import TShirts from './pages/TShirts';
import NotFound from './pages/NotFound';
import Cart from './pages/Cart';

function ScrollToTop() {
   const location = useLocation();

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [location]);

   return null;
}

function App() {
   return (
      <Router basename="/">
         <div className="App">
            <Header />
            <main className="main">
               <Routes>
                  <Route path="/" element={<Products />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/hoodies" element={<Hoodies />} />
                  <Route path="/tshirts" element={<TShirts />} />
                  <Route path="*" element={<NotFound />} />
               </Routes>
            </main>
            <Footer />
         </div>
         <ScrollToTop />
      </Router>
   );
}

export default App;
