
export default function LastProductsTitle() {
   return (
      <section className="last-products">
         <div className="container">
            <div className="last-products__body">
               <div className="last-products__partners scrolling-text-container">
                  <p className="last-products__partner" id="scrolling-text">
                     last products | last products | last products | last
                     products | last products | last products | last products |
                     last products | last products | last products | last
                     products | last products | last products | last products |
                     last products | last products | last products | last
                     products | last products | last products | last products |
                     last products | last products | last products | last
                     products | last products |
                  </p>
               </div>
            </div>
         </div>
      </section>
   );
}
